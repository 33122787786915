
import { computed, defineComponent, ref, watch } from 'vue';
import { lostelkUrl } from '../../global';
import ValidateInput from '../form/ValidateInput.vue';
import ValidateForm from '../form/ValidateForm.vue';
import ConfirmationBox from '../globalFun/ConfirmationBox.vue';
import store from '../../store';
import createTooltip from '../globalFun/createTooltip';
import router from '../../router';
import { socket } from '../../service/service';

export default defineComponent({
  name: 'ReplyComment',
  components: {
    ValidateInput,
    ValidateForm,
    ConfirmationBox
  },
  emits: ['reloadReplyComments', 'replyCommentPositioning'],
  props: {
    replyCommentData: {
      type: Object,
      required: true
    },
    PostUserIdData: {
      type: Number,
      required: true
    },
    singleuserIdData: {
      type: Number,
      required: true
    },
    singleCommentData: {
      type: Object,
      required: true
    },
    postId: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const replyComment = computed(() => props.replyCommentData);
    const PostUserId = computed(() => props.PostUserIdData);
    const singleuserId = computed(() => props.singleuserIdData);
    const singleComment = computed(() => props.singleCommentData);
    // 接收当前文章的ID
    const postIdData = computed(() => props.postId);
    // 判断是否登录
    const isLogin = computed(() => store.state.user.isLogin);
    // 回复评论input的内容
    const replyCommentVal = ref();
    // 修改评论input的内容
    const reviseReplyCommentVal = ref(
      replyComment.value ? replyComment.value.content : ''
    );

    /**
     * 回复评论发表按钮点击事件允许或拒绝
     */
    const replyCommentButton = ref(false);
    watch(replyCommentVal, () => {
      if (replyCommentVal.value) {
        replyCommentButton.value = true;
      } else {
        replyCommentButton.value = false;
      }
    });

    /**
     * 修改评论发表按钮点击事件允许或拒绝
     */
    const reviseCommentButton = ref(false);
    watch(reviseReplyCommentVal, () => {
      if (reviseReplyCommentVal.value) {
        reviseCommentButton.value = true;
      } else {
        reviseCommentButton.value = false;
      }
    });

    /**
     * 监听输入最大字符长度
     * 新增
     * 修改
     */
    const isReplyCommentMax = ref(false);
    watch(replyCommentVal, () => {
      const commentMaximumReg = /^.{0,60}$/;
      if (commentMaximumReg.test(replyCommentVal.value)) {
        isReplyCommentMax.value = false;
      } else {
        replyCommentButton.value = false;
        isReplyCommentMax.value = true;
      }
    });

    const isReviseReplyCommentMax = ref(false);
    watch(reviseReplyCommentVal, () => {
      const commentMaximumReg = /^.{0,60}$/;
      if (commentMaximumReg.test(reviseReplyCommentVal.value)) {
        isReviseReplyCommentMax.value = false;
      } else {
        reviseCommentButton.value = false;
        isReviseReplyCommentMax.value = true;
      }
    });

    /**
     * 回复评论input框显示控制
     * 修改评论input框显示控制
     */
    const replyShow = ref(true);
    const reviseShow = ref(true);

    const showReplyInput = () => {
      reviseShow.value = true;
      replyShow.value = !replyShow.value;
      if (!replyShow.value) {
        setTimeout(() => {
          const inputFocus = document.getElementById(
            `reply-comment-publish-reply-input-id-${replyComment.value.id}`
          ) as HTMLElement;
          inputFocus.focus();
        }, 0);
      }
    };

    const showReviseInput = () => {
      replyShow.value = true;
      reviseShow.value = !reviseShow.value;
      if (!reviseShow.value) {
        setTimeout(() => {
          const inputFocus = document.getElementById(
            `reply-comment-publish-revise-input-id-${replyComment.value.id}`
          ) as HTMLElement;
          inputFocus.focus();
        }, 0);
      }
    };

    watch(replyShow, () => {
      replyCommentVal.value = '';
    });

    watch(reviseShow, () => {
      reviseReplyCommentVal.value = replyComment.value.content;
    });

    /**
     * 回复评论
     */
    const replyCommentClick = async () => {
      const publishReplyCommentData = {
        commentId: replyComment.value ? replyComment.value.id : '',
        publishReplyCommentData: {
          content: replyCommentVal.value,
          postId: postIdData.value,
          isReplyParentComment: 0
        }
      };
      await store
        .dispatch('publishReplyComment', publishReplyCommentData)
        .then(async () => {
          replyShow.value = true;
          // context.emit('reloadReplyComments');
          replyCommentVal.value = '';
          context.emit('replyCommentPositioning');
          createTooltip('Comment reply successful', 'success', 3000);
        });
    };

    /**
     * 修改评论
     */
    const reviseCommentClick = async () => {
      const reviseCommentData = {
        commentId: replyComment.value ? replyComment.value.id : '',
        reviseCommentData: {
          content: reviseReplyCommentVal.value
        }
      };
      await store.dispatch('reviseReplyComment', reviseCommentData).then(() => {
        replyShow.value = true;
        reviseShow.value = true;
        replyComment.value.content = reviseReplyCommentVal.value;
        createTooltip('Comments modified successfully', 'success', 3000);
      });
    };

    /**
     * 删除回复评论
     */
    const isDelete = ref(false);
    const showDeleteAddirm = () => {
      isDelete.value = true;
      reviseShow.value = true;
    };
    const cancelDelete = () => {
      isDelete.value = false;
    };
    const isdeleteSucceed = ref(false);
    const confirmDelete = async () => {
      isDelete.value = false;

      try {
        await store.dispatch(
          'deleteReplyComment',
          replyComment.value ? replyComment.value.id : ''
        );
        isdeleteSucceed.value = true;
        createTooltip('Comment deleted successfully', 'success', 3000);
      } catch (error) {
        // createTooltip(message, 'success', 3000);
      }
    };

    /**
     * 跳转登陆页
     */
    const goLogin = async () => {
      await router.push('/');
      await router.push('/login');
    };

    /**
     * 进入用户页
     */
    const toUserPage = (userId: number) => {
      router.push(`/@${userId}`);
    };

    /**
     * 监听实时服务端修改回复评论事件
     */
    const onUpdateReplyComment = (data: {
      replyCommentId: number;
      content: string;
      socketId: string;
    }) => {
      const { replyCommentId, content, socketId } = data;

      if (replyCommentId != replyComment.value.id) return;

      if (socket.id === socketId) return;

      replyComment.value.content = content;
    };

    socket.on('updateReplyComment', onUpdateReplyComment);

    /**
     * 监听实时服务端删除评论事件
     */
    const onCommentReplyDeleted = async (data: {
      replyCommentId: number;
      socketId: string;
    }) => {
      const { replyCommentId, socketId } = data;
      if (replyCommentId != replyComment.value.id) return;

      if (socket.id === socketId) return;
      isdeleteSucceed.value = true;
    };

    socket.on('commentReplyDelete', onCommentReplyDeleted);

    return {
      lostelkUrl,
      replyComment,
      PostUserId,
      showReplyInput,
      showReviseInput,
      singleuserId,
      reviseShow,
      showDeleteAddirm,
      replyCommentVal,
      reviseReplyCommentVal,
      reviseCommentButton,
      reviseCommentClick,
      isDelete,
      cancelDelete,
      confirmDelete,
      isdeleteSucceed,
      isReviseReplyCommentMax,
      singleComment,
      goLogin,
      isLogin,
      replyCommentButton,
      replyShow,
      replyCommentClick,
      isReplyCommentMax,
      toUserPage
    };
  }
});
