
import { defineComponent } from 'vue';
export default defineComponent({
  setup(props, context) {
    const cancel = () => {
      context.emit('cancelDelete');
    };
    const confirm = () => {
      context.emit('confirmDelete');
    };
    return {
      cancel,
      confirm,
    };
  },
});
